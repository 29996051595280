import { ApiService, createGetService } from '@ligo/shared/utils';
import { LegalCheckup, LegalCheckupType } from '../models/legal-checkup';
import { RESOURCES } from '../resources';

export const LegalCheckupsService = {
  ...createGetService<LegalCheckup>(RESOURCES.LEGAL_CHECKUPS),
  listTypes: createGetService<LegalCheckupType>(RESOURCES.LEGAL_CHECKUP_TYPES)
    .list,

  async updateStatus(uuid: string, status: 'done' | 'open') {
    await ApiService.patch(RESOURCES.UPDATE_LEGAL_CHECKUP(uuid), { status });
  }
};
