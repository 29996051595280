export default {
  criminal_record_label: `I declare that the shareholder(s) and director(s) have no criminal records;
    are not being associated with criminal activities in the media; and
    are not, or have not been for the last 4 years been, placed under state administration (onder bewind staan).
  `,
  terms_and_conditions_label: 'I accept the Terms and Condition of Ligo',
  have_read: 'I have read and agree to ',
  terms_and_conditions: 'Terms and conditions',
  criminal_record_alert:
    'You need to check the Criminal Records option before you can continue ',
  terms_and_conditions_alert:
    'In order to proceed, you first need to accept the General Terms and Conditions.',
  order_overview: 'Order overview',
  total: 'Total',
  subtotal: 'Subtotal',
  satisfied: 'Satisfied customers',
  ratings: 'Reviews',
  our_specialists: 'Our consultants are there for you and glad to help.',
  questions: 'Do you have any questions?',
  call_us: 'Call us',
  chat: 'Chat directly with a',
  support_agent: 'Legal Consultant',
  secure: 'Payments are secure and encrypted',
  notaryproduct: 'Notary Product',
  contract: 'Contracts',
  membership: 'Membership',
  notaryproductextra: 'Essentials',
  discount: 'Discounts',
  stablishment: 'Incorporation costs',
  additional: 'Essentials',
  name: 'Checkout',
  order_sumary: 'Order summary',
  accept: 'Yes, I would like to receive 3 free quotes',
  reject: "No, I'm not interested"
};
