

















import {
  defineComponent,
  ref,
  Ref,
  onMounted,
  PropType,
  watch
} from '@vue/composition-api';

type ArrowOrientation = 'left' | 'right' | 'top' | 'bottom';
type ArrowPosition =
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right'
  | 'left-top'
  | 'left-bottom'
  | 'right-top'
  | 'right-bottom'
  | 'top-center'
  | 'bottom-center'
  | 'left-center'
  | 'right-center';

const arrowOrientationMap: Record<ArrowOrientation, number> = {
  left: 225,
  right: 45,
  top: 315,
  bottom: 135
};

const arrowPositionMap: Record<
  ArrowPosition,
  {
    top: string;
    left: string;
    arrowTransform: string;
    containerTransform: string;
  }
> = {
  'top-left': {
    top: '20px',
    left: '-5px',
    arrowTransform: `rotate(${arrowOrientationMap['left']}deg)`,
    containerTransform: 'translate(30px, -23px)'
  },
  'top-right': {
    top: '20px',
    left: 'calc(100% - 5px)',
    arrowTransform: `rotate(${arrowOrientationMap['right']}deg)`,
    containerTransform: 'translate(calc(-100% - 30px), -23px)'
  },
  'bottom-left': {
    top: 'calc(100% - 30px)',
    left: '-5px',
    arrowTransform: `rotate(${arrowOrientationMap['left']}deg)`,
    containerTransform: 'translate(30px, calc(-100% + 25px))'
  },
  'bottom-right': {
    top: 'calc(100% - 30px)',
    left: 'calc(100% - 5px)',
    arrowTransform: `rotate(${arrowOrientationMap['right']}deg)`,
    containerTransform: 'translate(calc(-100% - 30px), calc(-100% + 25px))'
  },
  'left-top': {
    top: '-5px',
    left: '20px',
    arrowTransform: `rotate(${arrowOrientationMap['top']}deg)`,
    containerTransform: 'translate(-25px, 30px)'
  },
  'left-bottom': {
    top: 'calc(100% - 5px)',
    left: '20px',
    arrowTransform: `rotate(${arrowOrientationMap['bottom']}deg)`,
    containerTransform: 'translate(-25px, calc(-100% - 30px))'
  },
  'right-top': {
    top: '-5px',
    left: 'calc(100% - 30px)',
    arrowTransform: `rotate(${arrowOrientationMap['top']}deg)`,
    containerTransform: 'translate(calc(-100% + 25px), 30px)'
  },
  'right-bottom': {
    top: 'calc(100% - 5px)',
    left: 'calc(100% - 30px)',
    arrowTransform: `rotate(${arrowOrientationMap['bottom']}deg)`,
    containerTransform: 'translate(calc(-100% + 25px), calc(-100% - 30px))'
  },
  'top-center': {
    top: '-5px',
    left: 'calc(50% - 5px)',
    arrowTransform: `rotate(${arrowOrientationMap['top']}deg)`,
    containerTransform: 'translate(-50%, 30px)'
  },
  'bottom-center': {
    top: 'calc(100% - 5px)',
    left: 'calc(50% - 5px)',
    arrowTransform: `rotate(${arrowOrientationMap['bottom']}deg)`,
    containerTransform: 'translate(-50%, calc(-100% - 30px))'
  },
  'left-center': {
    top: 'calc(50% - 5px)',
    left: '-5px',
    arrowTransform: `rotate(${arrowOrientationMap['left']}deg)`,
    containerTransform: 'translate(30px, -50%)'
  },
  'right-center': {
    top: 'calc(50% - 5px)',
    left: 'calc(100% - 5px)',
    arrowTransform: `rotate(${arrowOrientationMap['right']}deg)`,
    containerTransform: 'translate(calc(-100% - 30px), -50%)'
  }
};

export default defineComponent({
  name: 'SLigoAssistantChatBalloon',
  components: {},
  props: {
    x: {
      type: Number,
      required: true
    },
    y: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    info: String,
    arrowPosition: {
      type: String as PropType<ArrowPosition>,
      default: 'top-left'
    },
    writeAnimation: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const typingTitle = ref('');
    const typingInfo = ref('');

    function typingText(text: string, typing: Ref<string>) {
      return new Promise((resolve) => {
        let i = 0;
        const interval = setInterval(() => {
          typing.value += text[i];
          i++;
          if (i > text.length - 1) {
            clearInterval(interval);
            resolve(i);
          }
        }, 50);
      });
    }
    if (props.writeAnimation) {
      typingText(props.title, typingTitle).then(() => {
        if (props.info) typingText(props.info, typingInfo);
      });
    } else {
      typingTitle.value = props.title;
      typingInfo.value = props.info;
    }

    function setStyles() {
      const styleChatBalloon = (document.querySelector(
        '.chat-balloon'
      ) as HTMLElement).style;
      const styleChatContainer = (document.querySelector(
        '.chat-container'
      ) as HTMLElement).style;
      styleChatContainer;

      styleChatContainer.setProperty('--container-top', `${props.y}px`);
      styleChatContainer.setProperty('--container-left', `${props.x}px`);

      const arrowPosition = arrowPositionMap[props.arrowPosition];
      styleChatContainer.setProperty(
        '--container-transform',
        arrowPosition.containerTransform
      );
      styleChatBalloon.setProperty('--arrow-top', arrowPosition.top);
      styleChatBalloon.setProperty('--arrow-left', arrowPosition.left);

      styleChatBalloon.setProperty(
        '--arrow-orientation',
        arrowPosition.arrowTransform
      );
    }

    onMounted(() => {
      watch(
        () => props,
        () => {
          setStyles();
        },
        { immediate: true, deep: true }
      );
    });
    return { typingTitle, typingInfo };
  }
});
