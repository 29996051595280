

























import { defineComponent, PropType } from '@vue/composition-api';
import { AppLink } from '@ligo/shared/components';
import { AuthError } from '@ligo/bv-flow/store';
import { Dictionary } from '@ligo/shared/utils';
import { useAuthErrors } from './hooks/auth';

export default defineComponent({
  name: 'BannerError',
  components: { AppLink },
  props: {
    error: { type: Object as PropType<AuthError> },
    redirectUrl: String,
    appRoot: String,
    providerLinks: { type: Object as PropType<Dictionary> }
  },
  setup(props, { root }) {
    const { errorMsg, errorLinks, errorURLs } = useAuthErrors(props, root);

    return {
      errorMsg,
      errorLinks,
      errorURLs
    };
  }
});
