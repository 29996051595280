export default {
  no_account: 'Your account',
  linkedin: 'Continue with LinkedIn',
  google: 'Continue with Google',
  go_to_dashboard: 'Go To Dashboard',
  login: 'Login',
  logout: 'Log out',
  signup: 'Sign up',
  to_signup: 'You don’t have an account? Sign up ',
  here: 'here',
  reset: {
    start: 'Forgot your password?',
    here: 'here',
    end: 'to send you an email to reset it.',
    title: 'Reset your',
    password: 'password',
    send: 'Get a reset link',
    nevermind: 'Nevermind!',
    back: 'Take me back to login screen',
    email: 'Enter the email address you use to sign in',
    error:
      'There was an error while resetting your password, please check that you have a Ligo account'
  },
  check_mail: {
    check: 'Check your',
    email: 'email'
  },
  to_signin: 'Are you already a Ligo member? ',
  next: 'Next',
  mail_error: "We can't find this email",
  social_login: 'Or login with social media:',
  social_register: 'Or register with social media:',
  login_header: {
    title: 'Login to continue',
    subtitle:
      'Existing customers can login here. No account yet? Create an account here.'
  },
  register_header: {
    title: 'Create an account to continue',
    subtitle:
      'By creating an account, everything will be prepared for you in your dashboard. Already a customer? Login here.'
  },
  confirmation_header: {
    title: ' Resend a confirmation email',
    message_success: 'Confirmation email sent',
    message_error: "We couldn't find a user with this email address.",
    subtitle:
      'Please confirm your email address before proceeding. You do this by clicking on the link in the email you received. This is necessary to maintain access to your account'
  },
  errorCodeMsg: {
    different_provider:
      'Your e-mail address is already known to us. Continue with'
  },
  errorCodeLink: {
    different_provider: {
      google_oauth2: 'Google',
      linkedin: 'LinkedIn',
      no_provider: 'Your Provider'
    }
  },
  terms_of_service: {
    prefix: 'I agree with the',
    terms: 'General Terms and Conditions',
    suffix: 'of Ligo BV'
  },
  keep_posted: 'Keep me informed about legal tips and new features',
  confirmation_email_sent: 'Confirmation Email Sent'
};
