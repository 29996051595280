export interface AssistantState {
  prepareDocumentAssistant: {
    isActive: boolean;
  };
}

const state: AssistantState = {
  prepareDocumentAssistant: {
    isActive:
      localStorage.getItem('prepareDocumentAssistant') === 'true' ||
      localStorage.getItem('prepareDocumentAssistant') === null
  }
};

export default state;
